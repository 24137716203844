import React from 'react';
import '../App.css';

const PAnel = () => (
<div className="container">
        <div className="tab-body">
            <ul className="nav nav-pills ">
                <li className="active"><a href="#galerija3_1" data-toggle="pill"> Gumarstvo1 </a></li>
                <li><a href="#galerija3_2" data-toggle="pill"> Gumarstvo2</a></li>
                <li><a href="#galerija3_3" data-toggle="pill"> Drvna</a></li>
                <li><a href="#galerija3_4" data-toggle="pill"> Opekarstvo</a></li>
                <li><a href="#galerija3_5" data-toggle="pill"> Radio</a></li>
                <li><a href="#galerija3_6" data-toggle="pill"> Novinarstvo</a></li>
                <li><a href="#galerija3_7" data-toggle="pill"> Domar</a></li>
                <li><a href="#galerija3_8" data-toggle="pill"> Elektronika</a></li>
                <li><a href="#galerija3_9" data-toggle="pill"> Racunari</a></li>
                <li><a href="#galerija3_10" data-toggle="pill"> Gradjevia</a></li>
                <li><a href="#galerija3_11" data-toggle="pill"> Tehnika</a></li>
            </ul>
            <div className="tab-content">
                <div className='tab-pane fade in  active' id="galerija3_1">
                    <div className="row">
                        <h3 className="text-center naslovh2"> Rad u fizicko-hemisjkim laboratorijama</h3>
                        <p className="text-justify para" >
                            Ove poslove sam obavljao u periodu od 1982 do 1990. Oni su obuhvatali poslove
                            ispitivanja, uzorkovanja kako komponenti za izradu spoljnih automobilskih guma, tako i upravljanje
                            procesimau proizvodnji, odlucivanjem o upotrebi proizvedenih poluproizvoda. Poslovi su obuhvatali
                            rad u vise laboratorija. I to u Hemiskog, fizickoj, pripremnoj i laboratoriji za trenutnu
                            proveru kvaliteta izradjene gumene smese. Prilikom toga upotrebljavao sam viskozimerte proizvodjaca
                            Mosanto, abrazimetre Gudric, za testiranja pojave naprsnuca aparate firme De Matije, ali i ostalo hemisko
                            posudje, jer sam bio
                            obavezi da i vrsim hemisjka ispitvanja osobina hemijskih matreijala koji se
                            upotreljavaju u proizvodnji gume.
                            Naravno bilo je tu i razlicitih dinamometra kojima se merila jacina na kidanje,
                            relativno izduzenje,
                            veza izmedju gume i celika, guma guma, guma platno.
                            Tako da je to bio vrlo odgovoran posao, koji je pored svoje dinamicnosti i saradnje sa
                            ljudima u svim sluzbama
                            nephodnim za jedna kvalitetan i odgovoran rad prilikom proizvodnje Spoljnih auto guma.
                            Ove slike koje vidite nastale su sve osim ove sa hemijskim posudjem tih godina kada sam
                            to radio.
                            Ali, u obavezu na tim poslovima spadalo je i vodjenje dnevnika rada, pizanje izvestaja,
                            odlazak na sastanak
                            prilikom odlucivanja o mogucnoj upotrebi, vodjenje komletne arhive i poznavanje kretanja
                            dobijenih rezultata,
                            Arhiviranje podoataka, koji su se cuvali po pet do deset godina. Jer proizvod praksi smo
                            morali da pratimo i da njegove podtake cuvamo u slucaju auto nezgoda, trazeni su i takvi podaci.
                            Prakticno poziciji je pripadala kontroli kvaliteta, ali bila je u rangu tehnologije izrade auto guma.
                        </p>
                        <p className="text-justify para" >
                            Posao je bio pod visokim radnim pritiskom, prakticno timski i vrlo odgovran povezan sa
                            dosta sluzbi u
                            kompaniji. Zahtevao je visoku koncetraciju i preciznost. Jer sam prakticno morao da znam
                            kakve su sirovine od
                            kog proizvodjaca, da bih mogao da donosim odluke da li se nesto moze proizvoditi ili ne.
                            Tako da bih ovom prilikom mogao da istaknem kvalitet firme: Bajern iz Nemacke, Marub sa
                            Madaskara, Bekreat Holandija.
                            Ali i mnoge druge koje su tih godina postojale i bavile se izradom sirovina koje smo u
                            mojoj kompaniji Trajal upotrebljavali.
                        </p>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                            <img src={require('../img/viskozimetar.jpg')} className="img-thumbnail img-responsive" alt=".."/>
                        </div>
                        <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                            <img src={require('../img/hemija.jpg')} className="img-thumbnail img-responsive" alt=".."/>
                        </div>
                        <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                            <img src= {require('../img/laboratorija.jpg')} className="img-thumbnail img-responsive" alt=".."/>
                        </div>
                    </div>
                </div>
                <div className='tab-pane fade in' id="galerija3_2">
                    <div className="row ">
                        <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                            <h3 className="text-center "> Rad u kontroli finalnih proizvoda od gume</h3>
                            <img src={require('../img/meksiko.jpg')} className="img-thumbnail img-responsive" alt=".."/>
                        </div>
                        <div className="col-xs-12 col-sm-9 col-md-9 col-lg-9 naslovh2">
                            <p className="text-justify  ">
                                Послове које сам објављао на овој позицији од 1990-1996 31.августа, јер су ми да ли отказ
                                15. октобра 1996. уназад за 01. стембар 1996. У међувремену, прво забранили улазак у фабрику,
                                касније дали суспензију, нешто касније сам постао чувени директорски случај, мада ни данас
                                незнам шта је то тај случај.
                                посао се саатојао од конотроле и уједначености квалитета, како готовог производа спољних гума
                                за двоточкаше, тако и међуфазна контрола полупроизвода потребних за израду спољних гума. Тих
                                година сам увидео проблематику у тој производњи и дао комплетан пројект за решавње декласирних
                                гума-које су практично имале, али верујем и дан данас имају, функционалну грешку број један.
                                Покидане нити основе корда.
                                Там мојим пројектом се решавао тај проблем, али ја нисам члан партије на власти, па сам ето
                                постао директорски случај.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="tab-pane fade " id="galerija3_3">
                    <h3 className="text-center"> Rad u drvnoj industriji</h3>
                    <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                        <img src={require('../img/bansek.jpg')} className="img-thumbnail img-responsive" alt="....."/>
                        <img src={require('../img/abrihter.jpg')} className="img-thumbnail img-responsive" alt="....."/>
                        <img src={require('../img/cirkular.jpg')} className="img-thumbnail img-responsive" alt="....."/>
                    </div>
                    <div className="col-xs-12 col-sm-10 col-md-10 col-lg-10">
                        <p className="text-justify">
                            Sam rad je tamo poceo slucajno. Da priznam pre toga nisam nesto puno znao o drvetu, pa
                            cak i kada sam odlazio krajem jesni u bagrenjar da secem za ogrev zimi, precesto sam dozivljavao o
                            mog brata iz malo dalje familije malo posmeha, na svoj racun. Nije on to cinio iz neke zle namere
                            vec eto voleo je da se sali.
                        </p>
                        <p className="text-justify">
                            Ovo su samo jedna mali deo masina na kojima sa radio u toj drvnoj industriji. A poceo
                            sam od nosenja malih daskica do duzine od jednog metra. Ali eto intresovanje i majstori koji su zeleli
                            da njihovo znanje ne umre sa njima su mi pokazivali posao.
                        </p>
                        <p className="text-justify">
                            Bilo mi je tesko, ali posle nekih mesec dana vec sam poceo da vodim razvrstavanje dasaka
                            posle parenja u kazanima za tu svrhu. Taj posao mi je stvarno bio sto se kaze da poludis. Morao sam da
                            prvo odredim gde ce koja daska da se spakuje, prema velicini, kom standardu podleze po kvalitetu, da
                            neprestalno gledam da oni koji su pod mojom komandom imaju dovoljno prostora da se krecu, da se ne povrede, morao sam
                            da imam oci na sve strane. Ali tu mi je pala pata a porastao posao i obaveze.
                        </p>
                        <p className="text-justify">
                            Tako ide u Srbiji. Posto sma cesto odlazio u pogon jer su mi trebale manje letvice sa
                            izmedju dasaka da bi se susile, cesto sam morao da upotrebljavam stolarske masine jer su u pogonu svi nesto
                            jurili neku normu, koju masine nisu mogle da ispune. Pa je bilo dosta kvarova i povreda. Tako su me zapazili da umem sa masinama,
                            pa cak i sa odbacenim testerama sekao sam ono sto mi je bilo potrbno.
                        </p>
                        <p className="text-justify">
                            I tako me prebacise u pogon za potrebe placa- tj., pakovanja drvene gradje. Tu sam se
                            sureo i sa ostalim masinama:
                            Bansekom ili vertikanom rastruznom testerom, automackim sirklatima, kratilicama,
                            abrihterima i ostalim masinama.
                            No sto ti daju veci poso oni ti smanje platu pa sam posle skoro godinu dana naputio ovaj
                            posao iz navedenih razloga.
                        </p>
                    </div>
                </div>
                <div className="tab-pane fade " id="galerija3_4">
                    <h3 className="text-center naslovh2"> Rad u ciglarsko opekarskoj industriji</h3>
                    <div className="row">
                        <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                            <img src={require('../img/cigla.jpg')} className="img-thumbnail img-responsive" alt="..."/>
                        </div>
                        <div className="col-xs-12 col-sm-9 col-md-9 col-lg-9">
                            <p className="text-justify">
                                U ovoj fabrici sam bio do samog pocetka rata 1999. Bili su jako korektni.
                                Radilo se devet sati u smeni. Plata je bila dobra, cak je bilo i nesto preko toga.
                                Poslovi koje sam radio je pakovanje gotove robe i njeno odvajanje po kvalitetu.
                                Poneto znanje iz drugih oblasti koje sam imao, zeleo sam da sa njima podelim i
                                da bude bolje svima.
                            </p>
                            <p className="text-justify">
                                Jer su govorili kako im odlaze velike pare na samotiranje vagona koji ulaze u pec
                                gde se pece cigla i drugi proizvodi. U vreme pauze odlazio sam da vidim sta se sve
                                tu radi i na koji nacin. Imao sam razgovore sa tehnologom koji je vodio taj posao, ali
                                on je za kratko vreme otisao u penziju jer je bio dosta star.
                            </p>
                            <p className="text-justify">
                                Obavio sam neke razgovore sa odogovornima u ciglani, ali dobio oprecne odgovore, pa
                                sam i ucutao. Jer sto bi ja silonm nekoga na nesto. Ako je vec resio da se davi, a nemogu
                                da ga odgovorim od toga, a preti da i mene udavi. A moglo se zatvoriti ciklus u proizvodnji
                                ocinjanjem od odpadne polomljene robe i peska i cementa praviti malo veci blokovi kako se
                                to ovde zove. To su gotove pare koje su oni bacali. Drugo sto sam uocio je to da zbog lose
                                organizacije u delu pre te peci gde se roba pece ljudi rade u normativu i onda da bi ispunili
                                normu oni su na vagone postavljali i losu robu, umesto da to ide u ponovnu preradu i time se
                                smanji utrosak energenata za pecenje i pravljenja skarta.
                            </p>
                            <p className="text-justify">
                                No to nema ko da cuje i razume i tako to ide sve u krug.
                                Dosao je rat, Bilo je da ne pricam kako, ali posle sam otisao na neke druge poslove.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="tab-pane fade " id="galerija3_5">
                    <h3 className="text-center naslovh2"> Rad u radiju</h3>
                    <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                        <img src={require('../img/work.JPG')} className="img-thumbnail img-responsive" alt=".."/>
                        <img src={require('../img/antena.jpg')} className="img-thumbnail img-responsive" alt=".."/>
                        <img src={require('../img/sinisa.jpg')} className="img-thumbnail img-responsive" alt=".."/>
                        <h5 className="text-center"> Sinisa Matejic</h5>

                    </div>
                    <div className="col-xs-12 col-sm-9 col-md-9 col-lg-9">
                        <p className="text-justify para">
                            Ovaj posao sam dobio zahvaljujuci kako se kasnije ispostavilo mom kumu Armonikasu Sinisi
                            Matejicu.Posto sam radio dosta poslova, jer mora da se zivi, Sto kazu i mecke vodio, sli to sam
                            stvarno jednom prilikom i uradio. Tacnije moj prijatelj i ja smo platili Ciganinu koji je vodio mecku,
                            da nam dopustida je malo povedemo i mi. Plasio se ali na kraju pristao. Mogu da kazem da je meca bila
                            veoma pitoma.Tako da mi je sada zao sto su im vlasti otele te zivotinje koje su bile deo njihove
                            familije porodice.I vlast se ogresila o te zivotinje i o Cigane. Jer su se toliko srodili da verujem da je
                            svima bilo jako tesko.I tako sam vodio mecku.

                            Ali vratimo se poslu u radiju. Vec sam postavljao neke antene za i ostalo sto je trebalo
                            kod mog prijatelja Ljubise Bogdanovica u Pojatu, gde je ziveo i Sinisa u komsiluku, koji je ima privatni
                            radio koji se zvao TOP.I on dodje do mene i rece. Triko znam da znas svasta i da se ne plasis visine i ostalog,
                            postao sam urednik Radiija Cicevac, a nece niko da nam namesti antene jer stub je neobezbedjen tako su mi kazali,
                            nego ako oces dodji sutra dolaze ovi iz Mihajlovic televizije jer su oni dali te antene i predajnik. Sta sam mogao
                            prisatao i dosao kada smo dogovorili. Stub je bio dobro napravljen nekih dvadest i nesto metara, ali nije imao
                            anker sajle pa se klatio kao pijan, ali rec je rec.

                            Slicne su bile antene ovima na ovoj slici. Popeo sam se gore, Nije bas bilo za veselje.
                            zvukao jednu pa drugu pa kablove i razdelnik, i ostali potreban pribor. Mnogo znojeva me izbilo, ali sta je tu
                            je. Postavio to kako je to bilo u dokumentaciji sisao i otisao kuci. Sinisa mi rece da za neki dan bice i para. I stvarano
                            posle dogovorenog vremena on me pozva u studio i rece, znas sta, mislim da ti je bolje da dobijes posao jer nam
                            trebas ovde nego da ti daju pare. Videcu da sredim to. Znam da neces da zivis samo od toga kao i do sada ali bice ti
                            bolje. I tako uradi smo.Posao bio je dobar, nemogu da se zalim, plata na vreme, uslovi dobri. Posle mi je Sinisa
                            dao jos obaveza jer se moglo i to da se radi. Pa sam tako slao vesti drugim medijima i obavljao posao saradnje sa
                            njima. Popravljao racunare u opstinskoj upravi i sve sto se pokrece na struju. Jer nisam navikao da dzabe sedim, kao ni
                            danas.

                            I jednog dana sretosmo se u kafani, bio je slobodan dan. I ja malo po malo u razgovru sa
                            Sinisom upitah ga za jednu zenu odkuda ona na veselju kod njega. On ucuta jer su mu tada bas ulazili sin i cerka.
                            Nasmeja se i rece im da znate od danas Trika, posto su me tako zvali, nije vise Trika vec je on nas kum. I tako posle nam
                            isprica celu pricu.

                            Ali svaka sreca ima i tugu. Doslo je do promene vlasti, pa su i meni zamenili
                            rukovodioce ubacijuci podobnije. Tako da je novi po naredjenju, jer sam pre toga pohvaljen za novogodisnju emisju, poceo
                            da me zlostavlja.I tako sam taj posao prestao da tamo da radim krajem marta 2005.
                            Ali zivot ide svojim tokom hvala mojim uciteljima i znanju koje mi podarise ziv se.
                        </p>
                        <img src={require('../img/potvrda radio rad.jpg')} className=" slika img-thumbnail img-responsive" alt=".."/>
                    </div>
                </div>
                <div className="tab-pane fade " id="galerija3_6">
                    <div className="row">
                        <h3 className="text-center naslovh2"> Rad u novinarstvu</h3>
                        <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                            <img src= {require('../img/prednja stranaebritic.jpg')} className="img-thumbnail img-responsive" alt=".."/>
                        </div>
                        <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                            <h5><a href="http://www.ebritic.com/"> Id kartica iz Britica</a></h5>
                        </div>
                        <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                            <img src= {require('../img/pobeeda148.jpg')} className="img-thumbnail img-responsive" alt=".." />
                        </div>
                        <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                            <h5>Objavljena moja poezija u Krusevackoj Pobedi</h5>
                        </div>
                        <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                            <img src= {require('../img/britic_pozija.png')} className="img-thumbnail img-responsive" alt=".."/>
                        </div>
                        <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                            <h5><a href="http://www.ebritic.com/?p=594467"> Deo objave moje kinjige pozeije RECI ISTINE u Briticu</a> </h5>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                            <img src= {require('../img/vizitka.jpg')} className="img-thumbnail img-responsive" alt=".."/>
                        </div>
                        <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                            <h5> <a href="http://www.serbroyalistau.com.au/"> Id kartica iz Srbske krune</a></h5>
                        </div>
                        <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                            <img src= {require('../img/srpska rec3.jpg')} className="img-thumbnail img-responsive" alt=".."/>
                        </div>
                        <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                            <h5> <a href="https://youtu.be/Rm99ey21Ro0?list=PLPQBfZaQFu4YglzyAs1__Ia4d6WPP9PHM">
                                Moje fotografije u Srpskoj reci Ceska</a> </h5>
                        </div>
                        <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                            <img src= {require('../img/kruna2010.jpg')} className="img-thumbnail img-responsive" alt=".." />
                        </div>
                        <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                            <h5> <a href="https://www.youtube.com/watch?v=IAuhcHFaFTw&t=0s&list=PLPQBfZaQFu4YglzyAs1__Ia4d6WPP9PHM&index=4">
                                Sastanak 2010 u Kragujevcu
                            </a>
                            </h5>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                            <img src= {require('../img/gostovanje1.jpg')}className="img-thumbnail img-responsive" alt=".."/>
                        </div>
                        <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                            <h5>
                                <a href="https://www.youtube.com/watch?v=HJahSFPFkKw&index=6&list=PLPQBfZaQFu4ZEdIsl8Vb6Ku0dDyg3exrX&t=8s">
                                    Jedno od gostovanja u Srbiji ovo je bilo na tv Devic Smederevska Palanka
                                </a>
                            </h5>
                        </div>
                        <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                            <img src= {require('../img/panteljemomn.jpg')} className="img-thumbnail img-responsive" alt=".."/>
                        </div>
                        <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                            <h5><a href="https://www.youtube.com/watch?v=duM49uy3580&t=0s&list=PLPQBfZaQFu4b0cFIgnSfsKb4txwzjQrEY&index=1">
                                Interviju za Svestenikom Pantelejmonom
                            </a> </h5>
                            <p className="text-justify para">
                                Пантелејмон Јовановић, био је у то време свештеник наше Србске цркве у Јовановом граду
                                односно како се он зове Јоханезбургу у Јужноафричкој републици. Где практично и постоји
                                црквено школска општина Свети Апостол Тома. У ту далеку територију посало га је наш пок.
                                патријах Павле, јер и тамо као и свуда на овоме свету има нас Срба.
                            </p>
                        </div>
                        <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                            <img src= {require('../img/sa-ocem-josifom-brno.jpg')} className="img-thumbnail img-responsive" alt=".."/>
                        </div>
                        <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                            <h5>Сусрет са Свештеником Јосифом у Брну - Чешка 2017.</h5>
                            <p className="text-justify para">
                                Десило је ово у некадашњој нашој цркви, данашњој руској цркви у граду Брну.
                                Свештеник Јосиф је дуго времена службовао по Србији, тако да смо разговарали на Србском
                                говору без икавих проблема. Тако сам сазнао доста тога из наше прошлости.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="tab-pane fade " id="galerija3_7">
                    <h3 className="text-center naslovh2"> Posao domara u centru za kulturu</h3>
                    <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                        <img src= {require('../img/domar.jpg')} className="img-thumbnail img-responsive" alt="..." />
                    </div>
                    <div className="col-xs-12 col-sm-9 col-md-9 col-lg-9">
                        <p className="text-justify para">
                            Pre nego sto su me zlostavljali na poslu, kao torturi da odem sam,
                            postavili su me na poziciju domara u tadasnjem centru za kulturu, gde kako vidite na ovoj slici
                            dodali su mi samo jos vise posla. Sto se vidi tacki osam.

                            Nisu se nadali da mogu i to da podnesem, ali zilav sam ti ja. Stizao sam i to
                            da radim. Novog direktora sam uozorio na porobleme koji mogu nastati ovakvim resenjem i
                            time sto on radi i nastavi me prisiljava. Naravno on je nastavio po nalogu vlasti
                            torturu.Tako a jednom prilikom kada sam u radiju imao problema na racunaru koji sam morao da
                            popravim kako bi radio nesmetano radio,on mi je naredio da odem da cistim neki hodnik. Rekao sam mu
                            onda pred svima, mada to nije bila moja praksa onu uticnicu koja jos nije kupljena da se zameni
                            upalice se studio samo da znas, a ti i dalje me zlostavljaj i salji na neko drugo bezvezno mesto.
                            Ponovio je naredjenje i dao pismeno. Mislo je da ce time postici nesto.

                            Otisao sam tamo kud mi je rekao, on je otisao kuci i posle nekog vremena radnici iz
                            radiija u planicnom strahu su dosli do mene i rekli da je tamo pozar. Slegao sam ramenima i rekao da ga zovu da mi
                            da drugo naredjenje jer je on tako hteo da bude.

                            Posle kraceg vremena dosao je bled kao krpa i promenio mi naredjenje. Nisam likovao jer
                            se moglo desitijos gore, kao kod direktora iz komunalne firme gde on mi nije dozovlio da odem, pa su
                            imali posla vatrogasci i jos neke druge sluzbe.Tako je to propalo.

                            Ali zivot ide dalje
                        </p>
                    </div>
                </div>
                <div className="tab-pane fade " id="galerija3_8">
                    <h3 className="text-center naslovh2"> Poslovi u elektronici i elektrici</h3>
                    <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                        <h5 className="text-center"> Jednopolna elektricna sema</h5>
                        <img src= {require('../img/sema.jpg')} className="img-thumbnail img-responsive" alt=".." />
                        <img src= {require('../img/sema1.jpg')} className="img-thumbnail img-responsive" alt=".."/>
                        <h5 className="text-center"> Detaljne elektricna sema</h5>
                        <img src= {require('../img/multi.jpg')} className="img-thumbnail img-responsive" alt=".."/>
                    </div>
                    <div className="col-xs-12 col-sm-9 col-md-9 col-lg-9">
                        <p className="text-justify para">
                            Ovo su samo neke od sema koje sam koristio u svom radu sa elektronikom, popravljajuci
                            razlicite uredjaje: od razglasnih audio pojacala i mikseta, do svih mogucih elektricnih
                            aprata u kuci, mada sam i postavljao elektricne kucne instalacije, singnalnu instalaciju
                            za davace inpulsa sa mikroprekidacima, radio nekoliko puta i sa telefonskim vise paricnim
                            kablovima.

                            Taj posao mi je pomogao da zivim i da kasnije kada sam bio bez posla, odrzim zivot
                            porodice. Krivac ako se tako moze nazvati za taj posao i znanje iz te oblasti je bio mog zet,
                            koji se i sam bavio time. Meni je to bila intersantna nauka zbog koje sam na pocetku pokvario
                            svoj radio aparat Nikola tesla, a kasnije poravio i njega i poceo da popravljam redom sve uredjaje.

                            Time sam prakticno poceo da se bavim 1978 pa na ovamo. Vise sam koristio u svom radu detaljne elektricneseme nego ove jednopolne, jer sam vise
                            radio popravke uredjaja koji su bili na nivou elemenata (diode traziostori integrisana kola kasnije, otpornici,
                            konedeznatori i slicno), nego na nivou blokova i principa rada( sto je bilo zastupljeno kod vesa masina i u
                            industriji). Ali mi ne smeta mogu da se snadjem i tamo malo jes teze ali nije neki problem, posto takve seme onekada
                            crtam firmama koje prave masine za dokumentaciju. Sve u svemu bitno je da se ne plasite i da pokusate da azumete, onda se vam se kazati
                            samo. Ovo dole ni\e je ne[to sto sam nekaqda pravio.
                            <img src= {require('../img/pretpojacalo.jpg')} className="img-thumbnail img-responsive" alt=".."/>
                        </p>
                    </div>
                </div>
                <div className="tab-pane fade " id="galerija3_9">
                    <h3 className="text-center naslovh2"> Poslovi vezani za rad u programima na racunaru</h3>
                    <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                        <img src= {require('../img/racunari.jpg')} className="img-thumbnail img-responsive" alt=".." />
                        <img src= {require('../img/radno12.jpg')} className="img-thumbnail img-responsive" alt=".."/>
                    </div>
                    <div className="col-xs-12 col-sm-9 col-md-9 col-lg-9">
                        <p className="text-justify para">
                            Rad u dosta programa je poceo sa kupovinom amige 500, sa kojom sam prakticno pravio male
                            uvode onima sto su snimali svadbe. Bilo je to nesto titlovanja preko staticne slike tada, kasnije bivalo je
                            drugacije. Ali nisam imao para da kupujem dzenlok i ostalo za video editing, pa sam krenuo sa poslovima priprema za
                            stampu i sito stampu.Kasnije kada sam kupio PC bilo jos nekih drugih programa. Ali to su bili oni trijal
                            programi koji su dobri za vezbu,jer im uvek nesto fali. Tako da bi se nesto uradilo treba dosta muka i snalazljivosti.
                            Jer moras istovremeno da radis u vise takvih programa.

                            Mada kada sam radio u radiju ovladao sam programima za obradu zvuka Sound Forge i ACID i
                            jos ponekima koji su trebali tada. Kada sam bio angazovan za obuku rada na racunaru za veb dizajn tada sam dosta
                            svladao Dream viwer i jos poneke kasnije na kursevima osnovu foto sopa, deluks paint, pa je tu bio i foto filter, ali
                            isto tako rad u vordu i notpad ++, Iza toga bilo je i nekih programa u Linkux operativnom sitemu, ali u poslednje vreme
                            naisao sam na Sony vegas, koji je stvarno izuzetan i ovako u portabl varijanti. Tako da bih voleo da ako bude prilike u
                            dogovoru sa proizvodjacem softvera napravim kurseve za taj program. Koji ima i animaciju i sve ono sto recimo bi trebalo u
                            slucaju Adobe premijera da ima i adobafterefect program i gomila mozda drugih. U Sony Vegasu sve to ima zajedno.
                            Naravno pokusao sam i demo verzije za programiranje PLC i HMI uredjaja kao sto je
                            Simensov Logo, kinko himvare, i Vinproloader. Ali bi mi tu trebalo jos prakticnog znanja.
                            Trenutno pisem knigu o paketu koji se zove Bootstrap, koji je izuzetan za oblast Veb dizajna.
                        </p>
                    </div>
                </div>
                <div className="tab-pane fade " id="galerija3_10">
                    <h3 className="text-center naslovh2"> Rad u gradjevinskoj struci</h3>
                    <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                        <img src={require('../img/kopijaplanaplaca.jpg')} className="img-thumbnail img-responsive" alt=".."/>
                        <p>.Ovako je bilo u dedinom placu a sada mome jos od kada ja znam za sebe</p>
                        <img src={require('../img/pogled  od seeroistoka.JPG')} className="img-thumbnail img-responsive" alt=".."/>
                        <img src={require('../img/pogled juzno.JPG')} className="img-thumbnail img-responsive" alt=".."/>
                        <img src={require('../img/seo pogleda preostalog sa zapada.JPG')} className="img-thumbnail img-responsive" alt=".."/>
                            <img src={require('../img/zadnji.JPG')} className="img-thumbnail img-responsive" alt=".."/>
                            </div>
                            <div className="col-xs-12 col-sm-7 col-md-7 col-lg-7">
                            <p className="text-justify para"> Ove slike sa leve strane su slike kuce koju je, moje majke ujak
                            pravio za svoju sestru to jest moju babu po majci daleke 1923,kada je deda se odvojio od svog
                            oca mog pradede Stojana Veljkovica od oca Mate Veljkovca. Taj plac je kupljen od Stanike Djurkovic,
                            prema kazivanjima koja sam cuo od dede i rodbine njegov otac Stojan je platio u novcu i dodao je
                            njivu iste velicine ar za ar kod Jevdinog vira u Glavicaru.
                            Kasnije negde 1965 su mi baba i deda poklonili tu kucu, koju su moj otac i majka nesto
                            poravili da se tu moglo i ziveti. Gornja kuca kod puta je stradala u zemljotresima koje su izazvali
                            punjenjima Djerdapskog i Celijskog jezera, tako da sam pre nekih 15 godina krenuo u restauraciju te
                            kuce i stigao do stepena koji se vidi na desnoj strani.
                            </p>
                            <p className="text-justify para">
                            Da bih sve ovo uradio trebalo je dosta knjiga i svega prouciti, jedino mi je zao sto
                            nisam mogao da upotrebljavam kao sto se to nekada radilo drvenu gradju, jer je drvo kao i sve drugo
                            dosta bolesno, pa je ne moguce pronaci drvo za te potrebe. Jer ono mora bit istaro bar nekih 60 godina
                            i to hrastovina, koja bi morala da se tese, a ne da se kao sto se to danas radi na banseku sece. Te sam
                            morao da ojacam i temelj i ostalo po potrebi temeljnim stopama, ostalo drvo koje je ostalo u zidovima
                            morao sam zameniti beronsko armirnaim stubovima. Jedino je ostala stara cigla,koju sam kako sam rasturao
                            zid cistio i pakovao i kasnije opet ugradjivao u restaurirani zid. Naravno bilo je tu problema i dimenzijom
                            same kuce jer je stari temelj odredjivao sve. Ali eto niko u pocetku nije mogao da razume sve sto sam i crtao
                            i govorio, kao sto ni danas mi kazu ne razumemo kako ces da postavis krov, jer ima zbog temelja problem
                            nisu sve dimenzije kuce jednake po duzini i sirini.
                            Tako je bilo i sa firmom koja nas je trovala neispravnom vodom, kada su me tuzili da im
                            dugujem za takvu vodu koju nisu smeli da mi salju. ja sam jednostavno iskopao bunar, sam do dubine od oko 6m
                            a kasnije su mi pomogla braca da iskopam do nekih oko 10m. Nema sta covek ako ima viziju nemoze da uradi,
                            jedino mora da ume da voli i sve cemu se samo kazati
                            </p>
                            </div>
                            <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                            <img src={require('../img/zidkameni1.jpg')} className="img-thumbnail img-responsive" alt=".."/>
                                <img src={require('../img/kucaj.jpg')} className="img-thumbnail img-responsive" alt=".."/>
                                <img src={require('../img/kucajjj.jpg')} className="img-thumbnail img-responsive" alt=".."/>
                                <img src={require('../img/kusacjj.jpg')} className="img-thumbnail img-responsive" alt=".."/>
                                <img src={require('../img/kusacjj.jpg')} className="img-thumbnail img-responsive"alt=".."/>
                                </div>
                                </div>
                <div className="tab-pane fade " id="galerija3_11">
                    <h3 className="text-center naslovh2"> Moja intersovanja i naucna istrazivanja u tehnici</h3>
                    <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                        <img src= {require('../img/ja 600.jpg')} className="img-thumbnail img-responsive" alt=".."/>
                        <img src= {require('../img/nikola l.jpg')} className="img-thumbnail img-responsive" alt=".."/>
                    </div>
                    <div className="col-xs-12 col-sm-7 col-md-7 col-lg-7">
                        <p className="text-justify para">
                            1. Posto nema neki odgovarajucih slika stavio sam svoju. Ono sto me interesuje
                            je su rezonantne frekvencije hemijskih elemenata i jedinjenja, kao njena primena
                            u drugim oblastima.

                            2. Konstrukcija motora sa stalnim magnetima, znaci bez ikavih namotaja.

                            3. izgradnja operativnog sitema pod nazivom Srbin tms

                            No koliko sam video u proteklih 15 godina nama sagovornika oko mene na nove teme,
                            a i oni koji se nadju ondmah pocinju da izmisljaju milion razloga da odu. Mada, mi je i
                            dr. Novak Vukoje rekao da su ta moja razmisljanja za neki 24 vek.

                            Neznam sta da o tome kazem ,ali verovatno ce i to vreme doci, mozda je to sto zelim
                            ispred vremena kao i primena PLC i racunara daleke 1990, kada sam tim projektom zelo da smanjim
                            skart u trajalu gde sam radio i da povezem sve u sistem gde bi svako prema svojim pristupni odobrenjima
                            moago da pristupa podacima, ili 1986 kada sam napisao projekat za izradu gumenog granulata od
                            svega sto je od gume i da se to mesa sa asfaltom i dobijemo i puteve bolje i nema zagadjenja.

                            Neznam samo znam da sam dao kompletan projekat 1996 kada sam bi o proglasen za
                            direktorskog slucaja, ali sam zavrsio kao socijalni, ratni i poratni i sada vise prema tim zakonima neznam koji sam vise
                            slucaj, samo sto nisam u pratiji na vlasti. Kao da oni pustaju kisu.

                            Ova druga slika ispod je nesto o cemu razmisljam ali nema sa kim da o tome govorim, jer je to
                            valjda za neko daleko vreme. Ne gubim nadu i posle kise sunce sija.
                        </p>
                    </div>
                </div>
            </div>
        </div>
 </div>
);

export default PAnel;
