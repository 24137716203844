import React from 'react';
import {Link} from 'react-router-dom';


const Meni = () => (

     <nav className="navbar navbar-inverse menii" style={{zIndex:500 }}>
        <div className="container">
            <div className="navbar-header">
            <button type="button" className="navbar-toggle collapsed"
                data-toggle="collapse" data-target="#navbar"
                aria-expanded="false" aria-controls="navbar">
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
            </button>
            <div className="navbar-brand"> <Link to="/">Sajt u React-u</Link></div>
        </div>

        <div id="navbar" className="navbar-collapse collapse">
            <ul className="nav nav-pills">
            <li> <Link to="/">Home</Link> </li>
            <li> <Link to="/messages">Kultura</Link> </li>
            <li> <Link to="/paneli">Znanja</Link> </li>
            <li> <Link to="/about">Sertifikati</Link> </li>
            <li> <Link to="/diplome">Diplome</Link> </li>
            <li> <Link to="/nestovise">Nesto Vise</Link> </li>
            <li> <Link to="/kontakt">Kontakt</Link> </li>
            <li> <Link to="/pane">Probni PAnel</Link> </li>
        </ul>
        </div>
     </div>
  </nav>
);

export default Meni;
