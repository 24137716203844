import React from 'react';
import '../App.css';

const Sertifikati = () => (
    <div className="container nestovise odozgo" >
        <div className="row">
            <h3 className="text-center" style={{color: "lightcoral"}}> Понешто о делу овоме и о мени </h3>
        </div>
        <div className="row">
            <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3" style={{paddingright: "20px"}}>
                <p className="text-justify">
                    Да би сте ово гледали на линковима са мог канала Србија култура мио ттраја потребно је да се
                    конектовани
                    на интернет, пошто се одтуда скида сигнал.
                    На овом линку можетe погледати кратке секвенце нашег обичаја који се зове Бела недеља</p>>
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe src="https://www.youtube.com/embed/videoseries?list=PLPQBfZaQFu4bYDPTY3lXE1GUr2S6Gn5el"
                      title="video1"      webkitallowfullscreen='' mozillowfullscreen allowfullscreen></iframe>
                </div>
                <p className="text-justify"> Нa овом је још једно моје дело видео едитинг где сам преставио оно што
                    још могу по неки у Србији да из скоро ничега направе
                </p>>
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe src="https://www.youtube.com/embed/xtB7U-7KVcQ?list=PLPQBfZaQFu4YExjuLNN7HCOtImr_Nt9M0"
                            title="video2"      webkitallowfullscreen mozillowfullscreen allowfullscreen></iframe>
                </div>
                <p className="text-justify">
                    Ово сам скоро урадио прилог говори о једном човеку који ето када бог то одреди он направи
                </p>>
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe src="https://www.youtube.com/embed/JlW5yuPD2-0?list=PLPQBfZaQFu4Z7E9CC5XNa2IGKz7oKYAh1"
                            title="video3"  webkitallowfullscreen mozillowfullscreen allowfullscreen></iframe>
                </div>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                <h3 className="text-center"> Поштовани моји</h3>
                <p className="text-justify">
                    Волео бих да се можемо видети и бити заједно и ближе, али неко се побринуо да буде овако
                    како је.
                    Чика Стеву, како га називам из поштовања, упознао сам преко интеренета. Али, према речима и
                    силикама
                    које је постављао на интернету и оно што смо размењивали преко емаилова, указивало је на
                    његову
                    величину. Реч која се употребљава у нашем Србскоме говору ЧОВЕК, неможе превести на тај тамо
                    изведени говор
                    од нашег, који се назива Енглески. Тако, вама који сте око њега у томе немогу помоћи, али
                    дубоко сам уверен
                    да ће Чика Сева умети да вам објасни шта је то бити Човек.
                    О томе могу вам само написати да је то јако тешко постати и бити. Јер човек употребљава све
                    божије дарове.
                    И они може а за разлику од осталих и види свим својим бићем.
                </p>
                <h3> Nastanak ovog malo duzeg o meni</h3>
                <h5 className="text-justify">
                    Za ovo nastajanje ovog dela kriv je moj sin Stevica koji se eto bavi programiranjem i kada
                    sam god hteo
                    da ga pitam
                    nesto da kako se to Srbski kaze idem na prece on mi je uzvratio ono sto i ja nekada njemu
                    " uzmi knjigu procitaj pa ces znati i neces da pitas nesto glupo"
                    I ja tako na njegov predlog uzeh sa iterneta Bootstrap, napravih ovo i napisak jos malo i
                    knjigu na
                    Srbskom govoru, da i drugi na taj nacin nauce nesto da rade.
                </h5>
                <h5 className="text-justify">
                    Eto zovem se Miodrag Trajanovic, rodjen u Cupriji, zivim u Cicevcu, sve na slovo C, u
                    Srbiji.
                    Pokusavam da odem malo dalje u svet, kako bih nacinio mozda jos veca dela, neznam, eto neka
                    bude
                    mozda. Jer zelim da moj projekt <b>Srbi preko</b> se pokrene jos vise.
                </h5>

            </div>
            <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3" align="center" style={{paddingleft: "20px"}}>
                <p className="text-justify">
                    Ово је наше село ког се и Чика Стева сећа, али и оно умире и то уме да јако боли оне који
                    разумеју
                </p>
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe src="https://www.youtube.com/embed/-EGFu_fCwgk?list=PLPQBfZaQFu4Z7E9CC5XNa2IGKz7oKYAh1"
                            title="video23"     webkitallowfullscreen mozillowfullscreen allowfullscreen></iframe>
                </div>
                <p className="text-justify">
                    Ово је једно од многи гостовања на локланим и регионалним медијима у циљу престављања нас
                    Срба и дела наших.
                    Ето волео бих да сам имао прилику да начиним интервијуе са нашим људима који су покренули
                    наш Србски свемриски
                    програм, што га је Тито продао америма 1953, али ето Данијела Сремац ми је једном написала
                    да jе Дејвид Вујић
                    доста блестан.
                </p>
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe src="https://www.youtube.com/embed/HJahSFPFkKw?list=PLPQBfZaQFu4ZEdIsl8Vb6Ku0dDyg3exrX"
                            title="video12"      webkitallowfullscreen mozillowfullscreen allowfullscreen></iframe>
                </div>
                И на крају ово сам покушао да сам кажем понешто о себи

                <div className="embed-responsive embed-responsive-16by9">
                    <iframe src="https://www.youtube.com/embed/videoseries?list=PLPQBfZaQFu4bjP1UY6LEfAtn_Z5kVU0dE"
                            title="video4"       webkitallowfullscreen mozillowfullscreen allowfullscreen></iframe>
                </div>
            </div>
            <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                <img src={require('../img/2016.jpg')} className="img-thumbnail img-responsive" alt="..."/>
                <h5>
                    Ovo dole je samo jos nesto sto govori o nasoj saradnji i velicini dela nasega
                    Ali i moja zahvalnost za medijsku podrsku urednicima i ekipama u redakcijama
                    <a href="https://www.ebritic.com/">Britica u London Engleska</a>
                    i <a href="http://www.serbroyalistau.com.au/">Srbskoj krunu u Australiji</a>.
                    <a href="http://meatexportaustralia.webs.com/">Branko Rasic Australiji</a>. Bez njih ne bih
                    mozda ovo ni
                    pravio. Tako da se zahvaljujem Uredniku Stanimiru Smiljanicu i Branku Rasicu, na svemu.
                    Vredno je da pomenim i jos jednu osobu koja mi je bila urednik moja knjige poezije
                    Teodoru Todu Matic koja zivi u Sidneju.
                </h5>
                <p className="text-justify">
                    Практично ово је један део тога. Овим престављам Доктора сликарства Дарка Трајановића вама.
                </p>
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe src="https://www.youtube.com/embed/Z-43r0rSI4s?list=PLPQBfZaQFu4aXwFRcugpPh7G7vyDCoX7s"
                            title="video5" frameborder="0" allow="autoplay; encrypted-media" webkitallowfullscreen mozillowfullscreen
                            allowfullscreen></iframe>

                </div>
            </div>
        </div>
    </div>
            );

            export default Sertifikati;
