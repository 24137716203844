import React,{Component} from 'react';

 class Home extends Component{
    render(){
        return(
               <div>
                   <div className="container home">
                      <h2 className="text-center"> О себи самом - About my self </h2>
                      <div className="row">
                          <div className="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                              <h3 className="text-center"> Почетак</h3>
                              <p className="text-justify">
                                  Увек ми је било тешко да пишем и говорим о себи, јер сам учен од мојих учитеља да је увек
                                  најбоље
                                  да о мени говоре моја дела и други који су непристрасни и скромни људи. Али, некада и човек
                                  сам мора
                                  да проговори и себи самом и да се огласи како би други сазнали нешто о њему.
                              </p>
                              <p className="text-justify">
                                  Тако ћу и ја само овом приликом да покушам да што боље преставим себе сама, кроз оно што
                                  знам, умем и могу.
                                  На самом почетку небих да вас можда збуним оним што сам радио до сада и што сам практично
                                  научио.
                                  У животу су ме учили да је потребно да бих водио кућу и свет у њој, да све послове који се
                                  ту обављају требам
                                  и морам знати како бих могао да одлучујем ваљано и како то треба. Верујем да ће те можда
                                  помислити, ала овај
                                  пише хвалећи себе сама. Но, није тако и није ми то намера. Зато што свако дело излази на
                                  видело, па ће и ово
                                  моје тако једног дана се приказати у свом свом светлу. Да ли ћу бити поносан на њега или не,
                                  то незнам, то
                                  остаје да се види када се оно прикаже. До тада могу или можемо нагађати шта ће бити.
                              </p>
                              <h3 className="text-center"> Шта могу</h3>
                              <p className="text-justify">
                                  Па могу и доста и ништа у зависности од вас самих. Зато ћу и приказати своје могућности,
                                  знања,шта сам све радио
                                  до сада, о чему размишљам, шта ме интерсује и томе слично. Уједној колони биће моје речи на
                                  мом Србском говору у
                                  другој на Еглеском говору који је као и други настао на извору мог Србског. Али о томе можда
                                  неки други пут, јер
                                  о томе мораће једнога дан и много ученији од мене да говоре како то мора и треба да говоре.
                                  Практично, све зависи од вас који ми омогућите посао. Како? У животу сам увек поштовао
                                  постигнуте договоре, те бих
                                  то и захтевао од вас, будућих послодаваца. Послове учим ако их незнам да они постану део
                                  мене сама и да их разумем.
                                  Када је потребно додатно знање спреман сам да учим и стичем нова сазнања, јер је знање основ
                                  свега и оно даје сигурност
                                  која је неопходна да би се било који посао урадио. Те, ако ми се омогући да учим веомасе
                                  брзо могу прилагодити потребама
                                  новог посла.
                              </p>
                          </div>
                          <div className="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                              <h3 className="text-center"> The Start</h3>
                              <p className="text-justify">
                                  It was always difficult to write and talk about myself, because I learned from my teachers
                                  that it is
                                  always best to talk about me my work and others that are fair and modest people.
                                  But sometimes a man I must speak to himself and to other ads to find out something about
                                  him.
                              </p>
                              <p className="text-justify">
                                  So I'll just use this opportunity to try to better bump itself, through what I know,
                                  understand and can do.
                                  At the beginning I thought you might be confused by what I've done so far and what I learned
                                  in practice.
                                  In life I was taught that we need in order to guide the house and world unjoj that all the
                                  tasks that need to
                                  perform, and I need to know so that I can decide validly and how it should be. I believe
                                  that you might think,
                                  this ala writes praising himself. No, it is not and is not my intent. Because each work
                                  comes Navid, but this is
                                  my will that one day appear in his his light. Will
                                  I be proud of it or not, I do not know, it remains to be seen when it is displayed. Until
                                  then, can or can guess
                                  what will happen..
                              </p>
                              <h3 className="text-center"> What I can do</h3>
                              <p className="text-justify">
                                  So can a lot and nothing depending on yourself. Therefore, I will show their capabilities,
                                  knowledge, what I've
                                  been doing so far, I'm thinking about what I intersuje and the like. In one column I will
                                  say in my speech in
                                  the second srbska and private citizens in the speech that he and others formed the source of
                                  my Srbskog.
                                  But that maybe some other time, because that will have one day and a lot more knowledgeable
                                  than me to say that
                                  it should and should not say. Practically, it all depends on you to give me my job. How? In
                                  my life I have always
                                  respected the agreements reached, and it would require of you, future employers. Jobs teach
                                  if they do not know
                                  that they become a part of me alone and to understand them. When you need extra knows I'm
                                  ready to learn and acquire
                                  new knowledge, because knowledge is the basis of everything and it gives the security that
                                  is essential to any
                                  business done. And, if we allow to learn very quickly be adapted to the needs of a new job.
                              </p>
                          </div>
                          <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                              <img src={require('../img/moj_portret.jpg')} className="img-thumbnail img-responsive" alt=".."/>

                          </div>
                      </div>
                  </div>

               </div>
        );
    }
}

export default Home;


