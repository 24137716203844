/**
 * Created by trika on 12-May-18.
 */
import React from 'react';
import '../App.css';

const Sertifikati = () => (
    <div className="container">
        <div className="tab-body">
            <ul className="nav nav-pills ">
                <li className="active"><a href="#galerija5_1" data-toggle="pill"> Diplome </a></li>
                <li><a href="#galerija5_2" data-toggle="pill">Svedocanstva</a></li>
                <li><a href="#galerija5_3" data-toggle="pill"> Preporuke</a></li>
                <li><a href="#galerija5_4" data-toggle="pill"> Ostalo</a></li>
            </ul>
            <div className="tab-content">
                <h3 className="text-center naslovh2"> Diplome,svedocanstva i preporuke</h3>
                <div className="tab-pane active " id="galerija5_1">
                    <div className="row">
                        <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                            <img src= {require('../img/diploma1.jpg')}className="img-thumbnail img-responsive" alt=".."/>
                        </div>
                        <div className="col-xs-12 col-sm-10 col-md-10 col-lg-10">
                            <h5 className="text-justify">
                                Ovu sam diplomu dobio u Jugoslovenoskoj Vojsci 1981.godine posle vise meseci
                                nastave, koja je obuhvatala
                                Protivpozarne aprate, preventivu, srestva, nacine i taktike gasenja pozara i slicno.
                                Bilo smo velika jednica
                                za te poslove tada. Tu sam proveo nekih osam mesci rada ali i imali smo vise pozara
                                vecih razmera, kao sto su
                                poljski pozari i samo upale, mada smo imali vise intervencija od poplava kada
                                nadodje Dunav.
                            </h5>
                            <p><a href="https://www.academia.edu/35431077/Resumen_del_Test_de_Competencias_Laborales">
                                Ovo je uradjen test mojih sposobnosti na jednom sajtu u Cileu Lima
                            </a> </p>
                            <p><a href="https://www.academia.edu/35431124/CV.doc">
                                Moj malo stariji CV uradjen na jendom od sajtova u Novom Zelandu
                            </a> </p>
                            <p><a href="https://fvm.academia.edu/MiodragTrajanovic">
                                Moj profil na sajtu Akademije
                            </a> </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                            <img src= {require('../img/diploma2.jpg')} className="img-thumbnail img-responsive" alt=".."/>
                        </div>
                        <div className="col-xs-12 col-sm-10 col-md-10 col-lg-10">
                            <p className="text-justify">
                                Obzirom na moja interesovanja i predhodna saznanja resio sam da i ovo dopunim
                                ako bih lakse i brze mogao da pronadjem posao od kog bih mogao pristojno da zivim i
                                da se i dalje usavrsavam. Znanja nikad nije dosta, uvek nesto novo, uvek neka
                                dopuna.
                                Valjda to tako ide. Na ovom kursu sam se prvi put susreo sa operativnim sistemom
                                Linux. Posto sam ranije imao racunare kao sto je bila Amiga 500, Amiga 1200, pa onda
                                PC 1,
                                i tako redom do sada vec neznam koji je redni broj iz ovog PC, a i pre toga bila je
                                neka
                                samogradnja racunara u Srbiji i ondasnjoj Jugosalaviji. Posto sam dosta pre toga
                                radio
                                popravke kucnih elektroniskih i elektricnih aprata, elektricne instalacije i drugo
                                vezano za struju
                                Resio sam da pokusam da sklopim taj racunara. On se zvao TIM 011, radila ga je grupa
                                oko Dunjica iz
                                Beograda. Poslao je mislio sam lak i nema problema. Ali kao i sve ovde uvek ima ne
                                dorecenih stvari.
                                Prvo sam kontaktirao neke ljude u Institutu MIhalo Pupin ( Momira Rangelova i
                                druge), pa neke u jednoj
                                firmi u beogradu Radio ton, ali ono sto sam uspeo to je da sam dobio praznu plocu za
                                racunarske komponente
                                i polcicu za ispravljacki modul.<br/>
                                Ostalo sam poceo da trazim svuda po ondasnjoj Jugoslaviji, ali posle toga sam 1989 i
                                1990 krenuo i po Austriji,
                                Nemackoj, i uspeo sam da pronadjem dosta toga, jedino nisam mogao da pronadjem flopi
                                kontroler, jer je TIM 011
                                upotrebljavao CPM operativni sistem, pa bi recimo od PC da bi to koristio trebale
                                mnogo prepravke.
                                Tako da mi je ovaj kurs dobro dosao da nesto jos dodaam od saznanja.
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                            <img src= {require('../img/dipsred1.jpg')} className="img-thumbnail img-responsive" alt=".."/>
                        </div>
                        <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                            <img src= {require('../img/dipsred2.jpg')} className="img-thumbnail img-responsive" alt=".."/>
                                </div>
                                <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                                <img src= {require('../img/dipsred3.jpg')} className="img-thumbnail img-responsive" alt=".."/>
                                </div>
                                </div>
                                <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <p className="text-justify">
                                Ovo je moja diploma iz Srednje skole. Ove ocene mog znanja nemoj da vas iznenade.
                                One su takve jer su neki
                                umni tako odlucili nebintno koliko ko i sta zna ili ume. O tome ce govoriti i
                                svedocanstva na sledecem
                                tab panelu. A desilo se to ovako. Posto sam eto umao velikih sklonisti ka ucenju i
                                isto primenjivao u
                                praksi i tu i proveravao, mnoo cega sam uspevao da da razumem vise od svojih
                                vrsnjaka u odeljenju.
                                Tako da sam mnoge radove iz tehnike prakticno sam radio i ako smo bili u grupi. Oni
                                zato dobiju ocene vise
                                a meni se skidaju, zato sto sam to tako radio. Premda sam i tim mojim drugarima
                                objasnjavao ono sto sam znao
                                da bi razumeli i oni. Ali sta je tu je. Sto se tice rukopisa tu sam stvarno los, a i
                                dvokorak u kosarci
                                eto i danas ne mogu da uradim kako treba. Sve je to zajedno dovelo da moji ucitelji,
                                na koje se ne ljutim
                                da mi smanje neke ocene, te eto tako nisam mogao da upisem elektroniku koju sam
                                voleo i ona je i danas deo mene.
                                Oni su hteli nesto, mozda ih ja nisam razumeo. Ko zna. Ali tako da sam posle osmog
                                razreda imao nedovoljan uspeh
                                za elektroniku, pa su me eto upisali opet neki strucni ljudi u hemiju. Tako sam
                                postao hemijski tehnicar, ali ne za dugo
                                prepravise to na gumarski tehnicar pa posle na gumarski tehnicar smera pneumatike. I
                                tako dodjo do mature.
                                Nije se imalo para da idem nekih 20 dana na pripremu za diplomski, jer bilo je slabo
                                materijalno stanje, pa sam eto
                                morao da popravljam televizore, radio aparate i drugo da bih zavrsio i tu skolu. Te
                                sam od tog planiranog 20 dana
                                bio tamo u fabrici nekih dan dva, ali sam ucen da resavam probleme kada ih sagledam
                                sa svih strana. Otisao direktno
                                tamo u fabricku halu gde je to trebalo. Kako bih dosao do potrebnih podataka za
                                diplomski rad.
                                Bilo je tu i pitanja za one radnike koji su to direktno radili, ali i onih koji su
                                imali velike diplome. Svako od njih mi
                                je objasnjavao problematiku u proizvodnji unitrasnje gume.
                                Tehnolozi su mi dali i jedan primer recepture. Tako da sam uspeo da resim problem
                                podataka za diplomski veoma brzo, nepuna dva dana.
                                I kada sam to napisao na nekih 15 stranica i otisao na polaganje, pokazao komisiji,
                                video sam da je taj Zavisa bio
                                jedan od glavnih u toj komisiji, tako da je zahvaljuci njemu taj uspeh kakav je u
                                ovome sto se zove diploma.
                                Tada sam video da taj papir nije merilo znanja.
                                </p>
                                <p className="text-justify ">
                                Bilo mi je krivo malo, ali sta se tu moze, nisi u partiji na vlasti kako su mi
                                govorili sve do sada, kao da je to
                                bitno. Meni nije ali mnogima jeste zato su sada oni tako visoko, a ja eto moram da
                                trazim mogucnost da odem iz Srbije
                                zbog njih takvih.
                                </p>
                                </div>
                                </div>
                                </div>

                                <div className="tab-pane fade-in" id="galerija5_2">
                                <div className="row">
                                <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                                <img src= {require('../img/sved1razr.jpg')} className="img-thumbnail img-responsive" alt=".."/>
                                </div>
                                <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                                <img src= {require('../img/sved2razr.jpg')} className="img-thumbnail img-responsive" alt=".." />
                                    </div>
                                    <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                                    <img src= {require('../img/sved3razr.jpg')} className="img-thumbnail img-responsive" alt=".."/>
                                    </div>
                                    <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                                    <img src= {require('../img/sved4razr.jpg')} className="img-thumbnail img-responsive" alt=".."/>
                                    </div>
                                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                    <h5 className="text-justify">
                                    Ovo su svedocanstva iz srednje skole, koja u svetu se racuna kao skola sa nekim
                                    stepenom.
                                    Sta reci na kraju, Imao sam ucitelje raznolike, ucili su me kako su mogli znali
                                    umeli i
                                    oni sada u meni zive, i to njihovo znanje je samnom. Bez obzira na sve razumem ih i
                                    dosta
                                    njih je zivo i eto srecemo se i govorimo bez ljutnje ili neceg loseg. Jer svako od
                                    njih je
                                    iz nekih razloga bio takav. Eto odem i sada ko ponekih kada me pozovu kuci, kada im
                                    se ponesto
                                    od aprata pokvari, ili nekom nesto na racunaru nije jasno ili nece da im radi.
                                    Odem pogledam popravim i to je zivot. Ponekada se zato nasmejemo uz kafu.
                                    </h5>
                                    </div>
                                    </div>
                                    </div>

                                    <div className="tab-pane fade-in" id="galerija5_3">
                                    <div className="row">
                                    <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                                    <img src= {require('../img/srbskakruna.jpg')} className="img-thumbnail img-responsive" alt="..."/>
                                        <a href="https://www.youtube.com/watch?v=8eNaIgNOC1c&t=2s&list=PLPQBfZaQFu4YglzyAs1__Ia4d6WPP9PHM&index=1">
                                        Ovo je misljenje urednika Srbske krune </a>

                                        </div>
                                        <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">>
                                        <img src= {require('../img/milivojevicing1.jpg')} className="img-thumbnail img-responsive" alt=".."/>
                                        <p className="text-justify">
                                        Ovo je Preporuka firme
                                        </p>
                                        </div>
                                        <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                                        <img src= {require('../img/lipa potvrda.jpg')} className="img-thumbnail img-responsive" alt=".."/>
                                        <p className="text-justify">
                                        Ovo je Preporuka firme
                                        </p>
                                        </div>
                                        <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                                        <img src= {require('../img/ljuba.jpg')} className="img-thumbnail img-responsive" alt=".."/>
                                        <p className="text-justify">
                                        Ovo je Preporuka firme
                                        </p>
                                        </div>
                                        <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                        <p className="text-justify">
                                        Postoje i misljenja drugih u pisanom obliku o meni ali pomenucu samo jos dva
                                        jer bi bilo da se hvalisem sto mi nije cilj. Radeci tako na ineternetu i druzeci se
                                        sa mnogima od
                                        vas upoznao sam jednu divnu osobu, sa kojom sam eto proteklih godina razmenjivao
                                        price i na kraju
                                        mi je eto kako ga ja zovem Cika Steva Pirocanac, koji zivi u USA napisao jednu lepu
                                        kritiku, koja
                                        je prakticno pohvalno govorila o onome sto sam radim i pokusavam da uradim. Druga
                                        pohvala bila je
                                        za moj rad i delo od naseg svestenika Save Antica koji je u Velingtonu, na Novom
                                        Zelandu.
                                        </p>
                                        </div>
                                        </div>
                                        </div>

                                        <div className="tab-pane fade" id="galerija5_4">
                                        <div className="row">
                                        <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                                        <img src= {require('../img/resenje1.jpg')} className="img-thumbnail img-responsive" alt=".."/>
                                        <h5> Resenje o novom postavljenju od odrzavanje tehnike radio stanice u domara</h5>
                                        </div>
                                        <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                                        <img src= {require('../img/tehnika hrvacke.jpg')} className="img-thumbnail img-responsive" alt=".."/>
                                            <h5> Zahvalnica na ucescu od ABC Tehnike Hrvacke iz 1976 godine</h5>
                                            </div>
                                            <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                                            <img src= {require('../img/radio -domar.jpg')} className="img-thumbnail img-responsive" alt=".."/>
                                            <h5> Potvrda o poslovima koje trebam da obavljam po najnovijim tadasnjim nalozima
                                            direktora, gde na kraju prakticno znaci
                                            da morma da radim i ono sto njemu padne na pamet. Tako je to kada imamo prestrucne
                                            na visokim pozicijama.
                                            </h5>
                                            </div>
                                            <div className=" col-xs-12 col-sm-2 col-md-2 col-lg-2">
                                                <img src={require('../img/radio antena.jpg')} className="img-thumbnail img-responsive" alt=".."/>
                                            <h5>Zahvalnost i potvrdjivanje vlasnika Privatne radio stanice da je kod njega emitovana
                                            moja radio emisija posto je smetala na pod znakom navoda upravi na narodnom
                                            radiju</h5>
                                            </div>
                                            <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                                            <img src= {require('../img/radio1.jpg')} className="img-thumbnail img-responsive" alt=".."/>
                                            <h5> Resenje o novom postavljenju</h5>
                                            </div>
                                            </div>
                                            </div>
            </div>
        </div>
    </div>
);

export default Sertifikati;
