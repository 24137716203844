import React, {Component} from 'react';
class Kultura extends Component {
    render() {
        return (
            <div>
                <div className="container home">
                    <h3 className="text-center"> Rad u kulturi - Working in culture</h3>
                    <div className="row">
                        <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                            <img src={require('../img/ceska2.jpg')} height=" 450px"
                                 className="img-thumbnail img-responsive" alt=".."/>
                            <p className="text-justify">
                                Posle mog gostovanja u Pragu 2008.godine dosta toga se uradilo na sirenju istine o
                                ovom loklaitetu nedaleko od mesta Cicevac
                            </p>
                        </div>
                        <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                            <img src={require('../img/selu u pohode cicevac.jpg')}
                                 className="img-thumbnail img-responsive" alt=".."/>
                            <p className="text-justify">
                                Obziorm da pisem poeziju i druge pisane materijale
                                Bio sam ucesnik i ove manifestacije
                            </p>
                        </div>
                        <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                            <img src={require('../img/kruna2010.jpg')} className="img-thumbnail img-responsive" alt=".."/>
                            <p className="text-justify">
                                Ovo je objava u Srbskoj kruni o sastanku svih saradnika iz Srbije tada
                                koji se desio u Kragujevcu 2010. godine, kada je i urednik Branko Rasic
                                bio prisutan.
                            </p>
                        </div>
                        <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                            <img src={require('../img/kruna20101.jpg')} className="img-thumbnail img-responsive" alt=".."/>
                            <p className="text-justify">
                                Na slici je Druga stranica Srbske krune sa svim onim sto objasnjava postojanje i
                                prikazuje sastav urednistva i dopisnih clanova.
                            </p>
                        </div>
                        <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                            <img src={require('../img/alekisnac.jpg')} className="img-thumbnail img-responsive" alt=".."/>
                            <p className="text-justify">
                                Obziorm da pisem poeziju i druge pisane materijale Bio sam ucesnik i ove manifestacije
                            </p>
                        </div>
                        <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                            <img src={require('../img/Scan.jpg')} className="img-thumbnail img-responsive" alt=".."/>
                            <p className="text-justify">
                                Jedna od naslovnih stranica Srbske krune gde sam svojim radom i mogucnostima prestavio
                                nase
                                Srbe koji zive i rade u Juznoj Africi ne samo nasima u Australiji vec i na drugim
                                mestima u
                                svetu. Tako da se jos jednom cula istina o postojanju.
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                            <img src={require('../img/soko banja.jpg')} className="img-thumbnail img-responsive" alt=".."/>
                            <p className="text-justify">
                                Obzirom da pisem poeziju i druge pisane materijale Bio sam ucesnik i ove manifestacije
                            </p>
                        </div>
                        <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                            <img src={require('../img/srbskakruna.jpg')} className="img-thumbnail img-responsive" alt=".."/>
                            <p className="text-justify">
                                Ovim su clanovi redakcije i Urednik Srbske krune prakticno vrednujuci moj rad i
                                zalaganje
                                predlozili Udruzenju novinara Srbije da me prihvate usvoje clanstvo, sto ni danas nije
                                prihvaceno.

                            </p>
                        </div>
                        <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                            <img src={require('../img/srpska rec1.jpg')} className="img-thumbnail img-responsive" alt=".."/>
                            <p className="text-justify">
                                <a href="https://youtu.be/Rm99ey21Ro0?list=PLPQBfZaQFu4YglzyAs1__Ia4d6WPP9PHM">
                                    Ovim sam prakticno postаo i tada saradnik lista Srpska rec u Ceskoj, jer su
                                    ove moje slike koje vidite ostale u vise bojeva tih Srbskih novina tamo.
                                </a>
                            </p>
                        </div>
                        <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                            <img src={require('../img/vinska diploma149.jpg')}
                                 className="img-thumbnail img-responsive" alt=".."/>
                            <p className="text-justify">
                                Obzirom da pisem poeziju i druge pisane materijale. Bio sam ucesnik i ove manifestacije
                            </p>
                        </div>
                        <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                            <img src={require('../img/vrsac zlatni prsti.jpg')}
                                 className="img-thumbnail img-responsive" alt=".."/>
                            <p className="text-justify">
                                Obzirom da pisem poeziju i druge pisane materijale
                                Bio sam ucesnik i ove manifestacije

                            </p>
                        </div>
                        <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                            <img src={require('../img/toda i ja.jpg')} className="img-thumbnail img-responsive" alt=".."/>
                            <p className="text-justify">
                                Prakticno Teodora je donela Novine nasih Srba koji zive i rade u Rumuniji
                                ,nekadasnja Dacka Srbija, koje smo prestavili u Srbiji, verujem prvi put.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default Kultura;
