import React, { Component } from 'react';
import About from './components/About';
import Doplome from './components/diplome';
import Futer from'./components/futer';
import Home from './components/Home';
import Meni from'./components/meni';
import Messages from './components/Messages';
import Kontakt from './components/model';
import NestoVise from './components/nestovise';
import Paneli from './components/paneli';



import './App.css';
import {  Route,  Switch,  Redirect} from 'react-router-dom';
class App extends Component {
    render() {
        return (
            <div className="App">
            <header className=" container App-header">
                <Meni />
            </header>

            <Switch>
                <Route path="/about" component={About} />
                <Route path="/diplome" component={Doplome}/>
                <Route exact path="/"  component={Home} />
                <Route path="/messages" component={Messages}/>
                <Route path="/kontakt" component={Kontakt} />
                <Route path="/nestovise" component={NestoVise} />
                <Route path="/paneli" component={Paneli} />

                <Redirect to="/" />
            </Switch>
            <Futer/>
            </div>
    );
    }
}

export default App;

