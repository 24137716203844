
import React from 'react';
import '../App.css';

const Sertifikati = () => (
    <div className="container odozgo">
        <div className="row ">
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <a href=" https://www.sololearn.com/Profile/509144/">
                    <img src=   {require('../img/solo.jpg')} width={100} className="desno img-thumbnail img-responsive pull-left" alt=".."/>
                </a>
                <a href="https://www.linkedin.com/in/miodrag-trajanovic-4559a161/">
                    <img src={require('../img/linked.jpg')} width={100} className=" img-thumbnail img-responsive pull-left" alt=".."/>
                </a>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <div className="row">
                    <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                        <img src={require('../img/vats.jpg')} width={80} className=" img-thumbnail img-responsive pull-left" alt=".."/><br/>
                    </div>
                    <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                        <p>+38166402583</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                        <img src={require('../img/viber.jpg')} width={80} className=" img-thumbnail img-responsive pull-left" alt=".."/><br/>

                    </div>
                    <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
    <p>+38166402583</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="alert collapse alert-success" id="uspesno">
                <strong>Postovani!</strong> Vasa poruka je poslata .
                <a href="/" className="close" data-dismiss="alert" aria-label="close">&times;</a>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <h3>Mozete putem ove forme poslati email</h3>
                <form >
                    <div className="input-group">
                        <span className="input-group-addon">Tema poruke </span>
                        <input id="subjekt" type="text" className="form-control" name="subjekt"
                               placeholder="Tema poruke "/>
                    </div>
                    <div className="input-group">
                        <span className="input-group-addon"><i className="glyphicon glyphicon-user"></i></span>
                        <input id="ime" type="text" className="form-control" name="ime" placeholder="Svoje ime "/>
                    </div>
                    <div className="input-group">
                        <span className="input-group-addon"><i className="glyphicon glyphicon-inbox"></i></span>
                        <input id="email" type="text" className="form-control" name="email" placeholder="Email"/>
                    </div>
                    <div className="input-group">
                        <span className="input-group-addon"><i className="glyphicon glyphicon-lock"></i></span>
                        <input id="password" type="password" className="form-control" name="password"
                               placeholder="Password"/>
                    </div>
                    <div className="input-group">
                        <span className="input-group-addon"><i className="glyphicon glyphicon-envelope"></i></span>
                        <textarea id="msg"  className="form-control" name="msg"
                                  placeholder="Prostor za ostavljanje pooruke"/>
                    </div>
                    <button className="btn-danger pull-left" type="reset" >Resetuj</button>
                        <button className="btn-success pull-right" type="submit" name="posalji"
                                data-toggle="collapse" data-target="#uspesno">Posalji</button>
                </form>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <h3>
                    Dodatne infromacije
                </h3>
            </div>
        </div>
        <br/>
        <div className="google-maps ">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d23074.537432248846!2d21.441146427288807!3d43.703957143357805!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47569ab97687560b%3A0x6adb83accf31be2a!2zVG9tacSHYSBNaWxpxIdhLCDEhmnEh2V2YWM!5e0!3m2!1sen!2srs!4v1526032607086"
              title="mapa"      width={600} height={450}  style={{border:"0"}} allowFullScreen></iframe>
        </div>
        <br/><br/><br/>
    </div>
);

export default Sertifikati;
