import React from 'react';
import '../App.css';

const Sertifikati = () => (
    <div className="container">
        <div className="tab-body">
            <ul className="nav nav-pills ">
                <li className="active"><a href="#galerija4_1" data-toggle="pill"> Kuresevi </a></li>
                <li><a href="#galerija4_2" data-toggle="pill"> Sertifikat</a></li>
                <li><a href="#galerija4_3" data-toggle="pill"> Predavac</a></li>
                <li><a href="#galerija4_4" data-toggle="pill"> Elektro</a></li>
            </ul>
            <div className="tab-content">
                <div className="tab-pane active" id="galerija4_1">
                    <h3 className="text-center naslovh2">Zavrseni kursevi </h3>
                    <div className="row">
                        <h5 className="text-center para">Na sajtu<a href="http://www.sololearn.com">
                            <strnog> www.sololearn.com</strnog></a> </h5>
                        <p className="text-justify para" >
                            Kurseve koje sam zavrsio a objavjeni su na internet sajtu <a href="http://www.sololearn.com">
                            Sololearn</a>.
                            Pomogli su mi da shvatim i bolje naucim rad u pojedinim progamskim govorima i da
                            savladam osnove njihovih
                            pravopisa. Tako da bih preporucio mnogima da steknu neka saznanja iz oblasti
                            progamiranja bas na ovom
                            sajtu.
                        </p>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                            <img src= {require('../img/css setrifikat.jpg')} className="img-thumbnail img-responsive" alt=".." />
                            <h5 className="text-center"> CSS Fundamentals course </h5>
                        </div>
                        <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                            <img src= {require('../img/c.jpg')} className="img-thumbnail img-responsive" alt=".." />
                            <h5 className="text-center"> C++ Tutorial course </h5>
                        </div>
                        <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                            <img src= {require('../img/html setifikat.jpg')} className="img-thumbnail img-responsive" alt=".." />
                            <h5 className="text-center"> HTML Fundamentals course </h5>
                        </div>
                        <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                            <img src= {require('../img/java setifikat.jpg')} className="img-thumbnail img-responsive" alt=".." />
                            <h5 className="text-center"> Java Tutorial course </h5>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                            <img src= {require('../img/jquery.jpg')}className="img-thumbnail img-responsive" alt=".." />
                            <h5 className="text-center"> JQuery Tutorial course </h5>
                        </div>
                        <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                            <img src= {require('../img/php setifikat kurs.jpg')} className="img-thumbnail img-responsive" alt=".." />
                            <h5 className="text-center"> PHP Tutorial course </h5>
                        </div>
                        <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                            <img src= {require('../img/sql.jpg')} className="img-thumbnail img-responsive" alt=".." />
                            <h5 className="text-center"> SQL Fundamentals course </h5>
                        </div>
                        <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                            <img src= {require('../img/Javascr sertifikat.jpg')} className="img-thumbnail img-responsive" alt=".." />
                            <h5 className="text-center"> Java Script basic course </h5>
                        </div>
                    </div>
                </div>

                <div className="tab-pane " id="galerija4_2">
                    <h3 className="text-center naslovh2">Sertifikat za veb diazjn</h3>
                    <div className="row " >
                        <div className="col-xs-12 col-lg-3 " style={{margintop: "20px;"}}>
                            <img src= {require('../img/sertifikat.jpg')} className="img-thumbnail img-responsive" alt=".." />
                        </div>
                        <div className="col-xs-12 col-sm-9 col-md-9 col-lg-9 naslovh2">
                            <p className="text-justify para" >
                                Obzirom da me interesovala ova oblast kao i oblasti koje su nacno povezane sa ovom
                                I moja zelja za sticanjem novih znanja pokrenuli su me da i ovo pored svega drugog
                                krenem da ucim i zavrsim sa uspehom.
                                Ovaj kurs trajao je dosta casova jer se moralo savladati znanje iz malo vise
                                programa,
                                kao sto je bilo osnova Photo Shop-a, dream veawer, instalcija loklanog servera,
                                postavljanje
                                na pravi servere na internetu i slicno. Ali je meni posluzio da istom prilikom posto
                                su
                                racunari gde se kurs obavljao bili povezani u jednu vecu racunarsku mrezu da i
                                dopunim znanje
                                o radu racunara u vecim mrezama koliko je to moglo.
                            </p>
                            <p className="text-justify para" >
                                Osim ovog sto sam ucio na kursu, putem internet konekcije sam jos vise imamo
                                mogucnosti da pogledam
                                razlicite tehnologije u samom veb dizajnu. Gde mi se posebno dopalo programiranje u
                                PHP-u i
                                java scriptu, sto sam kasnije i sam posle kursa jos ucio, jer sam shvatao da bez
                                toga i baze podataka
                                nema velike sigurnosti po izradjen sajt. 
                                Drugo sto sam shvatio je to da se kao i u Srbskome govoru moraju postovati pravila
                                lepog pisanja
                                pravopis i ostala pravila pisanja programskog koda. Razlog tome je sto takav nacin
                                rada omogucava
                                lakse i brze otklanjanje nastalog problema, a koji se na taj nacin svode na
                                minimalne problem.
                            </p>
                            <p className="text-justify para" >
                                Kurs je trajao nekih 130 casova.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="tab-pane " id="galerija4_3">
                    <div className="row">
                        <h3 className="text-center naslovh2"> Ucesce na kursu za veb dizajn kao predavaca</h3>
                        <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                            <img src= {require('../img/akreditacija kurseva.jpg')} className="img-thumbnail img-responsive" alt=".." />
                            <p>
                                Da b iste videli o ovome na sajtu ZOUV.rs kliknite na ovo dugme
                            </p>
                                <a className="btn btn-success"
                                   href="http://katalog.zuov.rs/Program2012.aspx?katbroj=282&godina=2012/2013">
                                    Akreditacija
                                </a>

                        </div>
                        <div className="col-xs-12 col-sm-9 col-md-9 col-lg-9">
                            <p className="text-justify para" >
                                Posto sam radio dosta dugo u mnogim programima i imao velikog predznanja, pored toga
                                i vise puta na zahtev mojih
                                poznanika u skoli govorio o racunarima deci na casovima informatike, na inicijativu
                                mog prijatelja magistra Milosa
                                Vujica pristao sam da uzmem ucesce na ovom moglo bi se nazvati kursu za prosvetne
                                radnike ili nastavnike i ucitelje.
                                Tehnika i sve drugo je mocno ako ga mozes i znas upotrebiti. 
                                Ovde se po njegovoj zamisli formirlo gradivo koje su trebali polaznici da savladaju
                                u nekom kracem periodu jer su imali
                                vec znanja iz ove oblasti, te je samo trebalo da im se malo precizinije objasne te
                                nove tehnologije koje bi oni mogli
                                da primene u radu i time osavremene svoje nastavne planove i programe.
                                Prakticno je bio zadatak da im se prenese znanje o znacaju, primeni i mogucnostima
                                veb tehnologije zasnovane na
                                HTML i CSS programiranju uz upotrebu templejta iz programa Dream vewer, ali i jos
                                dosta drugog medijalno materijala.
                            </p>
                            <p className="text-justify para" >
                                Kurs je dao dosta dobrih rezultata, u vise skola je to prikazano znanje pocelo da se
                                primenjuje, a sam kurs je podrzalo
                                dosta prosvetnih organizacija i bio zaveden na zvanicnom sajtu za prosvetu cija je
                                ovo slika koju gledate levo od ovog
                                teksta.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="tab-pane " id="galerija4_4">
                        <div className="row">
                            <h3 className="text-center naslovh2"> na sajtu www.alison.com</h3>
                            <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                                <img src= {require('../img/alison.jpg')} className="img-thumbnail img-responsive" alt=".." />
                            </div>
                            <div className="col-xs-12 col-sm-9 col-md-9 col-lg-9">
                                <p className="text-justify para" >
                                    ALISON’s Diploma Courses category includes diploma-level courses in a variety of
                                    professional subjects,
                                    such as project management, business management and entrepreneurship, HR, web
                                    design, psychology,
                                    customer service, programming, social media marketing, children’s studies, nursing
                                    and patient care,
                                    social work, IT management, statistics, mental health, Six Sigma, teaching skills
                                    for educators, carpentry,
                                    supply chain management, international tourism, and PE. These diploma-level courses
                                    will give the learner a
                                    comprehensive understanding of the subject. Upon successful graduation, the student
                                    will have gained valuable
                                    skills and training that will greatly enhance employability and career prospects.
                                    The category also includes an
                                    innovative work-force re-entry skills advanced diploma that will be of interest to
                                    anyone re-entering the
                                    workforce after an extended absence.
                                </p>
                                <p className="text-justify para" >
                                    Posto me uvek elektronika vuce kroz zivot i posltala je ne raskidivi deo mene sama
                                    uvek pronadjem vremena da nesto novo naucim. Tako je to i ovom prilikom na ovm sajtu
                                    bilo.
                                    Tacno je to da su me malo zbunili u veri neki mernih instrumenata, koji se u Srbiji
                                    ne koriste
                                    Osim toga ovo je kurs na Engleskom, tako da sam imao priliku da proveravam i svoje
                                    znanje
                                    kada je u pitanju taj izvedeni govor.
                                    Ono sto sam tu prakticno morao je i da pisem pitanja i odgovore, dok je sam kurs
                                    zahtevao veliku paznju. Ta paznja se svodila na to da sam morao neko pitanje i vise
                                    puta da procitam.
                                    Sreca sto nisam imao ograniceno vreme za ceo test, vec je vreme bilo naograniceno.
                                    Ali posle par dana sam i to prosao, jedino mi je ostao nerazjasnjeno gde se moze
                                    preuzeti diploma
                                    i da li to nesto kosta i kome se to placa, kao i kolika je mogucost da sa tim kursem
                                    se dobije neki
                                    posao na zapadu. Te bih zamolio sve one koji nest znanju o ovom sajtu
                                    <a href="https://alison.com/"> Alison com</a> da me obaveste.
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <h3> Centar Kognitiv - Kraguejvac </h3>
                            <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                                <img src= {require('../img/engl prevod ser.jpg')} className="img-thumbnail img-responsive" alt=".." />
                            </div>
                            <div className="col-xs-12 col-sm-9 col-md-9 col-lg-9">
                                <p className="text-justify para" >
                                    Kurs elektroinstalatera masina i postojenja
                                </p>
                                <p className="text-justify para">
                                    Kao i uvek sto je bilo to je i ostalo, da je struja i elektronika prakticno deo mene
                                    sama.
                                </p>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
        <br/>
        <br/>
        <br/>
    </div>
);

export default Sertifikati;
